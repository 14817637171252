import React, { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import { toast } from 'react-toastify';
import {
  // arrayOf,
  bool,
  func,
  // number,
  // oneOf,
  shape,
  string,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
const { types } = require('sharetribe-flex-sdk');

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
} from '@mui/material';

const GDRIVE_API_KEY = process.env.REACT_APP_GDRIVE_API_KEY || '';

import { useConfiguration } from '../../context/configurationContext';

const { Money } = types;

// import moment from 'moment-timezone';

import {
  // FormattedMessage,
  injectIntl,
  intlShape,
} from '../../util/reactIntl';
import {
  propTypes,
} from '../../util/types';

// import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import { isScrollingDisabled } from '../../ducks/ui.duck';
import {
  Button,
  // IconSpinner,
} from '../../components';

import css from './ImportProductsModal.module.css';

const removeParametersFromURLString = (url) => {
  const url_parts = url.split('?');
  return url_parts[0];
}

const CSVSection = (props) => {
  const {
    onCreateListingDraft = () => {},
    onImageUpload = () => {},
    onQueryOwnListings = () => {},
    categoryOverride = null,
    handleChange = () => {},
    closeModal = () => {},
  } = props || {};
  const config = useConfiguration();
  return (
    <div>     
      <p>Each row will import in draft status.</p>
      <Importer
        dataHandler={async (rows, { startIndex }) => {
          // required, may be called several times
          // receives a list of parsed objects based on defined fields and user column mapping;
          // (if this callback returns a promise, the widget will wait for it before parsing more data)
          // for (row of rows) {
          //   await myAppMethod(row);
          // }
          // console.log('rows', rows);
          let has_incorrect_category = false;
          const rowCanBeImported = (si) => {
            const {
              category,
              type,
            } = si;
            return true;
          };
          const filtered_rows = rows.filter(fi => rowCanBeImported(fi));
          // console.log('filtered_rows', filtered_rows);
          for (let i = 0; i < filtered_rows.length; i++) {
            const row = filtered_rows[i];
            const {
              title,
              description,
              price,
              stockUpdate,
            } = row || {};
            const vals = {
              availabilityPlan: {
                entries: [],
                timezone: 'Etc/UTC',
                type: "availability-plan/time",
              },
              title,
              description,
              price: new Money(parseInt(price || 0, 10), 'USD'),
              privateData: {},
              images: [],
            };
            if (parseInt(stockUpdate, 10)) {
              vals.stockUpdate = {
                oldTotal: null,
                newTotal: parseInt(stockUpdate, 10)
              };
            }
            const img_url_fields = ['img_url_1', 'img_url_2', 'img_url_3', 'img_url_4', 'img_url_5'];
            for (let j = 0; j < img_url_fields.length; j++) {
              const img_url_raw = row[img_url_fields[j]];
              if (img_url_raw) {
                const img_url = `${img_url_raw}`.indexOf('https://drive.google.com/file/d/') >= 0
                  ? `${removeParametersFromURLString(img_url_raw.replace('https://drive.google.com/file/d/', 'https://www.googleapis.com/drive/v3/files/')).replace('/view', '')}?key=${GDRIVE_API_KEY}&alt=media`
                  : `https://corsproxy.io/?${encodeURIComponent(img_url_raw)}`;
                // const headers = { 'Authorization': `Bearer ${GDRIVE_API_KEY}` };
                // console.log('img_url', img_url);
                await fetch(img_url)
                  .then((res) => res.blob())
                  .then((myBlob) => {
                    const file = new File([myBlob], 'image.jpeg', {type: myBlob.type});
                    return onImageUpload(
                      {
                        id: `${file.name}_${Date.now()}`,
                        file,
                      },
                      config.layout.listingImage,
                    );
                  })
                  .then((res) => {
                    const {
                      id,
                      imageId,
                    } = res?.data || {};
                    if (imageId) {
                      vals.images.push({
                        id,
                        imageId,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log('error uploading image', err.message);
                  });
              }
            }
            const publicData = {
              transactionProcessAlias: 'default-purchase/release-1',
              unitType: 'item',
              pickupEnabled: false,
              shippingEnabled: true,
              listingType: 'sell-new-products',
            };
            const pub_keys = [
              'primary_category',
              'brand',
              'sku',
              'shopify_product_id',
            ];
            pub_keys.forEach((pub_key) => {
              if (row[pub_key]) {
                publicData[pub_key] = row[pub_key];
              }
            });
            if (categoryOverride) {
              publicData.category = categoryOverride;
            } else if (row.category) {
              publicData.category = row.category;
            }
            if (Object.keys(publicData).length > 0) {
              vals.publicData = publicData;
            }
            // console.log('vals', vals, config);
            await onCreateListingDraft(vals, config)
              .then(() => {
                toast.success(`Imported row ${i + 1}`);
                return Promise.resolve();
              })
              .catch((err) => {
                toast.error(`Error importing row ${i + 1}: ${err.message}`)
              });
          }
          const num_not_imported = rows.filter((r, i) => !rowCanBeImported(r, i)).length;
          if (num_not_imported) {
            const error_array = [];
            if (has_incorrect_category) {
              error_array.push('incorrect category');
            }
            toast.error(`Skipped ${num_not_imported} rows due to ${error_array.join(' and ')}}`);
          }
          await onQueryOwnListings({}, '', config)
            .then(() => {
              console.log('queried own listings');
            })
            .catch((err) => {
              toast.error(`Error querying listings: ${err.message}`);
              console.log(err.message);
            });
        }}
        defaultNoHeader={true} // optional, keeps "data has headers" checkbox off by default
        restartable={false} // optional, lets user choose to upload another file when import is complete
        onStart={({ file, preview, fields, columnFields }) => {
          // optional, invoked when user has mapped columns and started import
          // prepMyAppForIncomingData();
          // console.log(preview, fields, columnFields);
        }}
        onComplete={({ file, preview, fields, columnFields }) => {
          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
          // showMyAppToastNotification();
          toast.success('Imported successfully');
        }}
        onClose={({ file, preview, fields, columnFields }) => {
          // optional, if this is specified the user will see a "Finish" button after import is done,
          // which will call this when clicked
          closeModal();
        }}

        // CSV options passed directly to PapaParse if specified:
        // delimiter={...}
        // newline={...}
        // quoteChar={...}
        // escapeChar={...}
        // comments={...}
        // skipEmptyLines={...}
        // delimitersToGuess={...}
        // chunkSize={...} // defaults to 10000
        // encoding={...} // defaults to utf-8, see FileReader API
      >
        <ImporterField name="title" label="Title" />
        <ImporterField name="primary_category" label="Primary Category" />
        <ImporterField name="price" label="Price" />
        <ImporterField name="description" label="Description" optional />
        <ImporterField name="brand" label="Brand" optional />
        <ImporterField name="sku" label="SKU" />
        <ImporterField name="shopify_product_id" label="Shopify Product ID" optional />
        <ImporterField name="stockUpdate" label="Stock Update" optional />
        <ImporterField name="img_url_1" label="Image URL 1" optional />
        <ImporterField name="img_url_2" label="Image URL 2" optional />
        <ImporterField name="img_url_3" label="Image URL 3" optional />
        <ImporterField name="img_url_4" label="Image URL 4" optional />
        <ImporterField name="img_url_5" label="Image URL 5" optional />
      </Importer>
      <div style={{ paddingTop: 10, paddingBottom: 30 }}>
        <h4>Import Options</h4>
        <p>category override: </p>
        <select
          name="categoryOverride"
          value={categoryOverride}
          onChange={(e) => handleChange({ categoryOverride: e.target.value })}
        >
          <option value="">Use Category Specified In CSV</option>
          <option value="house-plants">House Plants</option>
          <option value="plants">Plants</option>
          <option value="fertilizer-and-soil">Fertilizer &amp; Soil</option>
          <option value="pots-and-planters">Pots &amp; Planters</option>
          <option value="seeds">Seeds</option>
          <option value="tools-and-accessories">Tools &amp; Accessories</option>
          <option value="garden-gifts">Garden Gifts</option>
          <option value="sustainability">Sustainability</option>
        </select>
      </div>
    </div>
  );
};

export const ImportProductsModalComponent = props => {
  const {
    // currentUser,
    intl,
    // params,
    onCreateListingDraft,
    onImageUpload,
    onQueryOwnListings,
  } = props;
  const [open, setOpen] = useState(false);
  const [categoryOverride, setCategoryOverride] = useState('');

  const handleCSVFormChange = (update_obj) => {
    const allowable_keys = ['categoryOverride'];
    const update_state_obj = {};
    Object.keys(update_obj).forEach((key) => {
      if (allowable_keys.includes(key)) {
        update_state_obj[key] = update_obj[key];
      }
    });
    Object.keys(update_state_obj).forEach((key) => {
      if (key === 'categoryOverride') {
        setCategoryOverride(update_state_obj[key]);
      }
    });
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle>
          Bulk Import Products
        </DialogTitle>
        <DialogContent classes={{ root: css.root }}>
          <Grid container spacing={3} className={css.gridContainer}>
            <Grid item xs={12}>
              <CSVSection
                onCreateListingDraft={onCreateListingDraft}
                onImageUpload={onImageUpload}
                onQueryOwnListings={onQueryOwnListings}
                categoryOverride={categoryOverride}
                handleChange={(update_obj) => handleCSVFormChange(update_obj)}
                closeModal={closeModal}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={3} className={css.gridContainer}>
            <Grid item xs={8} />
            <Grid item xs={4}>
              <Button
                className={css.submitButton}
                onClick={() => {
                  setOpen(false);
                }}
                // ready={submitReady}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {
        !open && (
          <Button
            className={css.reportsButton}
            onClick={() => setOpen(true)}
          >
            Bulk Import Products
          </Button>
        )
      }
    </>
  );
};

ImportProductsModalComponent.defaultProps = {
  currentUser: null,
};

ImportProductsModalComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onQueryOwnListings: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const ImportProductsModal = compose(
  connect(mapStateToProps),
  injectIntl
)(ImportProductsModalComponent);

export default ImportProductsModal;
