import React, { useState, useRef } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import Reapcha from 'reaptcha';
import { toast } from 'react-toastify';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isStripeError } from '../../util/errors';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import {
  sendContactUsEmail,
} from '../../ducks/user.duck';

import {
  H4,
  Button,
  FieldTextInput,
  FieldSelect,
  Form,
} from '../../components';

import css from './ContactUsForm.module.css';

const ErrorsMaybe = props => {
  const { sendContactUsError } = props;

  const errorMessage = sendContactUsError ? (
    <FormattedMessage id="ContactUsForm.contactUsFailed" />
  ) : null;

  return errorMessage ? <div className={css.error}>{errorMessage}</div> : null;
};

const ContactUsFormComponent = props => {
  const {
    onSubmit = () => null,
    onSendContactUsEmail,
    sendContactUsInProgress,
    currentUser,
    ...restOfProps
  } = props;

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const verify = () =>{
    captchaRef.current.getResponse().then(res => {
        setCaptchaToken(res)
    });
  };

  const subjects = [
    {
      key: 'Park Seed Products',
    },
    {
      key: 'Vego Garden Products',
    },
    {
      key: 'Plant by Number Products',
    },
    {
      key: 'Crescent Garden Products',
    },
    {
      key: 'Green Promise Farms Products',
    },
    {
      key: 'Costa Farms Products',
    },
    {
      key: 'Shipping & Checkout',
    },
    {
      key: 'General Inquiries',
    },
  ];

  return (
    <>
      <FinalForm
        {...restOfProps}
        onSubmit={values => onSubmit({ ...values })}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          name: `${currentUser?.attributes?.profile?.displayName || ''}`,
          email: `${currentUser?.attributes?.email || ''}`,
        }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            sendContactUsError,
            disabled,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            pristine,
            ready,
            submitButtonText,
            form: formApi,
            values,
            currentUser,
          } = fieldRenderProps;

          const submitInProgress = inProgress || sendContactUsInProgress;
          const submitDisabled = pristine || invalid || disabled || submitInProgress;

          const handleFormSubmit = event => {
            const form_data = {
              ...values,
              token: captchaToken,
            };

            onSendContactUsEmail(form_data)
              .then((result) => {
                // console.log('result', result);
                const {
                  success,
                  message,
                } = result || {};
                if (success) {
                  formApi.change('message', '');
                  toast.success(message || 'Message sent successfully!');
                } else {
                  toast.error(message || 'Error sending message. Please try again.');
                }
              })
              .catch((err) => {
                console.log('err', err.message);
                toast.error('Error sending message. Please try again.');
              });           


            handleSubmit(event);
          };

          const classes = classNames(rootClassName || css.root, className, {
            [css.disabled]: disabled,
          });

          return (
            <Form className={classes} onSubmit={handleFormSubmit}>
              <ErrorsMaybe
                sendContactUsError={sendContactUsError}
              />

              <FieldTextInput
                id="name"
                name="name"
                className={css.fieldFullWidth}
                type="text"
                label="Name"
                validate={validators.required('Name is required')}
              />

              <FieldTextInput
                id="email"
                name="email"
                className={css.fieldFullWidth}
                type="email"
                label="Email"
                validate={validators.required('Email is required')}
              />

              <FieldSelect
                id="subject"
                className={css.fieldFullWidth}
                name="subject"
                label="I am inquiring about..."
              >
                {subjects.map(subject => (
                  <option key={subject.key} value={subject.key}>
                    { subject.key }
                  </option>
                ))}
              </FieldSelect>
              
              <FieldTextInput
                id="message"
                name="message"
                className={css.fieldFullWidth}
                type="textarea"
                label="Message"
              />

              <Button
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={ready}
                >
                {submitButtonText || (
                  <FormattedMessage id="ContactUsForm.submitButtonText" />
                )}
              </Button>
            </Form>
          );
        }}
      />

      {
        !currentUser
        && (
          <Reapcha
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            ref={captchaRef}
            onVerify={verify}
          />
        )
      }
    </>
  );
};

ContactUsFormComponent.defaultProps = {
  className: null,
  currentUser: null,
  stripeAccountError: null,
  disabled: false,
  inProgress: false,
  ready: false,
  savedCountry: null,
  stripeBankAccountLastDigits: null,
  submitButtonText: null,
  fieldRenderProps: null,
};

ContactUsFormComponent.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  stripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  savedCountry: string,
  stripeBankAccountLastDigits: string,
  stripeAccountFetched: bool.isRequired,
  submitButtonText: string,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, sendContactUsInProgress, sendContactUsError } = state.user;
  return {
    currentUser,
    sendContactUsInProgress,
    sendContactUsError,  
  };
};

const mapDispatchToProps = dispatch => ({
   onSendContactUsEmail: values => dispatch(sendContactUsEmail(values)),
});

const ContactUsForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ContactUsFormComponent);

export default ContactUsForm;
