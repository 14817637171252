import React from 'react';
import { string, bool } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SquareImage.module.css';

export const SquareImageComponent = props => {
  const {
    rootClassName,
    className,
    src,
    title,
    shrinkOnMobile,
  } = props;
  const classes = classNames(
    rootClassName || css.root,
    className,
    shrinkOnMobile ? css.shrinkOnMobile : null
  );

  return src ? (
    <span
      className={classes}
      style={{
        background: `url(${src}) center center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      {title}
    </span>
  ) : null;
};

SquareImageComponent.defaultProps = {
  className: null,
  rootClassName: null,
  src: null,
  title: null,
  shrinkOnMobile: false,
};

SquareImageComponent.propTypes = {
  rootClassName: string,
  className: string,
  src: string,
  title: string,
  shrinkOnMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SquareImage = injectIntl(SquareImageComponent);

export default SquareImage;

