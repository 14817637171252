/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as AdminOrderDetailPageLoader } from './AdminOrderDetailPage/AdminOrderDetailPage.duck';
import { loadData as AdminOrdersPageLoader } from './AdminOrdersPage/AdminOrdersPage.duck';
import { loadData as AuthenticationPageLoader } from './AuthenticationPage/AuthenticationPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as CartListingPageLoader } from './CartListingPage/CartListingPage.duck';
import { loadData as CategoryPageLoader } from './CategoryPage/CategoryPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as OrderDetailPageLoader } from './OrderDetailPage/OrderDetailPage.duck';
import { loadData as OrderDetailGuestPageLoader } from './OrderDetailGuestPage/OrderDetailGuestPage.duck';
import { loadData as OrdersPageLoader } from './OrdersPage/OrdersPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as ProfileSettingsPageLoader } from './ProfileSettingsPage/ProfileSettingsPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StoreOrderDetailPageLoader } from './StoreOrderDetailPage/StoreOrderDetailPage.duck';
import { loadData as StoreOrdersPageLoader } from './StoreOrdersPage/StoreOrdersPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AdminOrderDetailPage: {
      loadData: AdminOrderDetailPageLoader,
    },
    AdminOrdersPage: {
      loadData: AdminOrdersPageLoader,
    },
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    CartListingPage: {
      loadData: CartListingPageLoader,
    },
    CartCheckoutPage: {
      loadData: CartListingPageLoader,
    },
    CartFinalPage: {
      loadData: CartListingPageLoader,
    },
    CategoryPage: {
      loadData: CategoryPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    OrderDetailGuestPage: {
      loadData: OrderDetailGuestPageLoader,
    },
    OrderDetailPage: {
      loadData: OrderDetailPageLoader,
    },
    OrdersPage: {
      loadData: OrdersPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    ProfileSettingsPage: {
      loadData: ProfileSettingsPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StoreOrderDetailPage: {
      loadData: StoreOrderDetailPageLoader,
    },
    StoreOrdersPage: {
      loadData: StoreOrdersPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TermsOfServicePage: {
      loadData: TermsOfServicePageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
  };
};

export default getPageDataLoadingAPI;
