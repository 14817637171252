import { storableError } from '../../../../util/errors';
import { parse } from '../../../../util/urlHelpers';
// import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getStockData,
} from '../../../../util/api';

// ================ Action types ================ //

export const FETCH_ITEM_STOCK_REQUEST = 'app/EditListingStockPanel/FETCH_ITEM_STOCK_REQUEST';
export const FETCH_ITEM_STOCK_SUCCESS = 'app/EditListingStockPanel/FETCH_ITEM_STOCK_SUCCESS';
export const FETCH_ITEM_STOCK_ERROR = 'app/EditListingStockPanel/FETCH_ITEM_STOCK_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchItemStockInProgress: false,
  fetchItemStockError: null,
  itemStockData: null,
};

export default function editListingStockPanelReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ITEM_STOCK_REQUEST:
      return { ...state, fetchItemStockInProgress: true, fetchItemStockError: null };
    case FETCH_ITEM_STOCK_SUCCESS: {
      // const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchItemStockInProgress: false,
        itemStockData: payload.data,
      };
    }
    case FETCH_ITEM_STOCK_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchItemStockInProgress: false, fetchItemStockError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchItemStockRequest = () => ({ type: FETCH_ITEM_STOCK_REQUEST });
const fetchItemStockSuccess = response => ({
  type: FETCH_ITEM_STOCK_SUCCESS,
  payload: response,
});
const fetchItemStockError = e => ({
  type: FETCH_ITEM_STOCK_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const fetchItemStockData = (params) => (dispatch, getState, sdk) => {
  dispatch(fetchItemStockRequest());
  const {
    listingId,
  } = params || {};
  // const { page = 1 } = parse(search);

  // dispatch(fetchItemStockSuccess({ data: sample_order }));
  // return Promise.resolve();;
  return getStockData(listingId)
    .then(response => {
      // console.log('response', response);
      if (!response.success) {
        return Promise.reject(new Error(response?.data?.error?.message || 'Could not fetch orders'));
      }
      // dispatch(addMarketplaceEntities(response));
      dispatch(fetchItemStockSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(fetchItemStockError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchItemStockRequest());
  const {
    listingId,
  } = params || {};
  const { page = 1 } = parse(search);
  return Promise.resolve();

  // dispatch(fetchItemStockSuccess({ data: sample_order }));
  // return Promise.resolve();;
  return getStockData(listingId)
    .then(response => {
      console.log('response', response);
      if (!response.success) {
        return Promise.reject(new Error(response?.data?.error?.message || 'Could not fetch orders'));
      }
      // dispatch(addMarketplaceEntities(response));
      dispatch(fetchItemStockSuccess(response.data)); 
      return response;
    })
    .catch(e => {
      dispatch(fetchItemStockError(storableError(e)));
      throw e;
    });
};
