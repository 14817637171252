import React, { useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  NamedLink,
} from '../../../components';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    padding: '20px 30px',
  },
  rows: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    lineHeight: '34px',
  },
  column1: {
    paddingRight: 20,
    // borderRight: '1px solid #e0e0e0',
  },
  column2: {
    paddingLeft: 20,
  },
}));

const MyComponent = ({
  loading,
  login,
  wrong,
  clearWrongLogin,
  type = 'experience',
}: Props) => {
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null);

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true)
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false)
  };

  const classes = useStyles();

  const linkArray = {
    'experience': [
      { name: 'Sustainability Shop', page: 'ExperiencePage', params: { pub_experience: 'sustainability-shop' } },
      { name: 'Small Space Gardening', page: 'ExperiencePage', params: { pub_experience: 'small-space-gardening' } },
      { name: 'Garden To Table', page: 'ExperiencePage', params: { pub_experience: 'garden-to-table' } },
      { name: 'Gardening Essentials', page: 'ExperiencePage', params: { pub_experience: 'gardening-essentials' } },
      // { name: 'Shop Small & Local', page: 'ExperiencePage', params: { pub_experience: 'shop-small-local' } },
      { name: 'Rare & Unique Varieties', page: 'ExperiencePage', params: { pub_experience: 'rare-unique-varieties' } },
      { name: 'Indoor Gardening', page: 'ExperiencePage', params: { pub_experience: 'indoor-gardening' } },
      { name: 'Pollinator Garden', page: 'ExperiencePage', params: { pub_experience: 'pollinator-garden' } },
      // { name: 'DIFY', page: 'ExperiencePage', params: { pub_experience: 'dify' } },
    ],
    'pots-planters': [
      { name: 'Raised Beds', page: 'SubcategoryPage', params: { pub_category: 'pots-planters', pub_subcategory: 'raised-beds' } },
      { name: 'Rolling Planters', page: 'SubcategoryPage', params: { pub_category: 'pots-planters', pub_subcategory: 'rolling-planters' } },
      { name: 'Planters', page: 'SubcategoryPage', params: { pub_category: 'pots-planters', pub_subcategory: 'planters' } },
    ],
    'tools-accessories': [
      { name: 'Seed Starting', page: 'SubcategoryPage', params: { pub_category: 'tools-accessories', pub_subcategory: 'seed-starting' } },
      { name: 'Garden Tools', page: 'SubcategoryPage', params: { pub_category: 'tools-accessories', pub_subcategory: 'garden-tools' } },
      { name: 'Composters', page: 'SubcategoryPage', params: { pub_category: 'tools-accessories', pub_subcategory: 'composters' } },
      { name: 'Planning & Design', page: 'SubcategoryPage', params: { pub_category: 'tools-accessories', pub_subcategory: 'planning-design' } },
    ],
    'seeds': [
      { name: 'Vegetables', page: 'SubcategoryPage', params: { pub_category: 'seeds', pub_subcategory: 'vegetables' } },
      { name: 'Herbs', page: 'SubcategoryPage', params: { pub_category: 'seeds', pub_subcategory: 'herbs' } },
      { name: 'Flowers', page: 'SubcategoryPage', params: { pub_category: 'seeds', pub_subcategory: 'flowers' } },
    ],
    'plants': [
      // { name: 'Annuals', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'annual-plants' } },
      { name: 'Perennials', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'perennial-plants' } },
      // { name: 'Roses', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'roses' } },
      { name: 'Trees & Shrubs', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'trees-shrubs' } },
      { name: 'Edibles' , page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'edibles' } },
      // { name: 'Bulbs', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'bulbs' } },
      { name: 'Natives & Pollinator Plants' , page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'natives' } },
      { name: 'Ferns', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'ferns' } },
      { name: 'Grass', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'grass' } },
      { name: 'Vines', page: 'SubcategoryPage', params: { pub_category: 'plants', pub_subcategory: 'vines' } },
    ],
  };

  let main_link_text = null;
  switch (type) {
    case 'experience':
      main_link_text = 'Browse By Experience';
      break;
    case 'pots-planters':
      main_link_text = (
        <NamedLink name="CategoryPage" params={{ pub_category: 'pots-planters' }}>
          Pots &amp; Planters
        </NamedLink>
      );
      break;
    case 'tools-accessories':
      main_link_text = (
        <NamedLink name="CategoryPage" params={{ pub_category: 'tools-accessories' }}>
          Tools &amp; Accessories
        </NamedLink>
      );
      break;
    case 'seeds':
      main_link_text = (
        <NamedLink name="CategoryPage" params={{ pub_category: 'seeds' }}>
          Seeds
        </NamedLink>
      );
      break;
    case 'plants':
      main_link_text = (
        <NamedLink name="CategoryPage" params={{ pub_category: 'plants' }}>
          Plants
        </NamedLink>
      );
      break;
  }

  return (
    <div>
      <span
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        {main_link_text} <KeyboardArrowDownIcon />
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave}}
      >
        <div>
          <div className={classes.rows}>
            <div className={classes.column1}>
              {
                Array.isArray(linkArray[type])
                && linkArray[type].map((link, index) => (
                  <div>
                    <NamedLink name={link.page} params={link.params}>
                      {link.name}
                    </NamedLink>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default MyComponent