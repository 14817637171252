import React, { Component, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import {
  ValidationError,
  ExpandingTextarea,
  Button,
} from '../../components';

import css from './FieldVariantsInput.module.css';

const CONTENT_MAX_LENGTH = 5000;

const FieldVariantsInputComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    hideErrorMessage,
    ...rest
  } = props;
  // console.log('fti props', props);
  const [variantValue, setVariantValue] = useState(input?.value ? JSON.parse(input.value) : null);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const isTextarea = input.type === 'textarea';

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Textarea doesn't need type.
  const { type, ...inputWithoutType } = input;
  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
      [css.textarea]: isTextarea,
    });
  const maxLength = CONTENT_MAX_LENGTH;
  const inputProps = isTextarea
    ? {
        className: inputClasses,
        id,
        rows: 1,
        maxLength,
        ...refMaybe,
        ...inputWithoutType,
        ...rest,
        // value: formattedValue,
      }
    : isUncontrolled
    ? {
        className: inputClasses,
        id,
        type,
        defaultValue,
        ...refMaybe,
        ...inputWithoutValue,
        ...rest,
      }
    : { className: inputClasses, id, type, ...refMaybe, ...input, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  const changeVariantValue = (newVariantValue) => {
    setVariantValue(newVariantValue);
    props.input.onChange({
      target: {
        name: 'pub_variants',
        value: JSON.stringify(newVariantValue),
      }
    });
  };
  const subvariantValueChange = (e) => {
    const {
      target: {
        name: raw_name,
        value,
      },
    } = e;
    const pieces = raw_name.split('__');
    if (pieces.length >= 3) {
      const [
        parent_variant_key,
        name,
        index_raw,
      ] = pieces;
      const index = parseInt(index_raw, 10);
      // console.log(name, index, value);
      const newVariantValue = variantValue ? { ...variantValue } : {};
      const new_variants = [];
      const found_variant_index = newVariantValue.variants.findIndex((variant) => variant.key === parent_variant_key);
      if (Array.isArray(newVariantValue.variants[found_variant_index].subvariants)) {
        newVariantValue.variants[found_variant_index].subvariants.forEach((subvariant, subvariant_index) => {
          const target_variant = { ...subvariant}
          if (subvariant_index === index) {
            target_variant[name] = value;
          }
          new_variants.push(target_variant);
        });
      }
      newVariantValue.variants[found_variant_index].subvariants = new_variants;
      changeVariantValue(newVariantValue);
    }
  }
  const variantValueChange = (e) => {
    const {
      target: {
        name: raw_name,
        value,
      },
    } = e;
    const pieces = raw_name.split('_');
    if (pieces.length >= 2) {
      const name_pieces = [];
      let index_raw = '';
      for (let i = 0; i < pieces.length; i++) {
        if (i === pieces.length - 1) {
          index_raw = pieces[i];
        } else {
          name_pieces.push(pieces[i]);
        }
      }
      const name = name_pieces.join('_');
      const index = parseInt(index_raw, 10);
      // console.log(name, index, value);
      const newVariantValue = variantValue ? { ...variantValue } : {};
      const new_variants = [];
      if (Array.isArray(newVariantValue.variants)) {
        newVariantValue.variants.forEach((variant, variant_index) => {
          const target_variant = { ...variant}
          if (variant_index === index) {
            target_variant[name] = value;
          }
          new_variants.push(target_variant);
        });
      }
      newVariantValue.variants = new_variants;
      changeVariantValue(newVariantValue);
    }
  }
  const variantsValueChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;
    const newVariantValue = variantValue ? { ...variantValue } : {};
    newVariantValue[name] = value;
    changeVariantValue(newVariantValue);
  }
  const handleAddVariant = (e) => {
    e.preventDefault();
    const newVariantValue = variantValue ? { ...variantValue } : {};
    if (Array.isArray(newVariantValue.variants)) {
      newVariantValue.variants.push({
        name: '',
        key: '',
        description: '',
        cart_notes: '',
        sku: '',
        price: '',
        price_original: '',
        shipping_fee: '',
        shipping_fee_additional: '',
        shopify_variant_id: '',
        variants: [],
      });
    } else {
      newVariantValue.variants = [{
        name: '',
        key: '',
        description: '',
        cart_notes: '',
        sku: '',
        price: '',
        price_original: '',
        shipping_fee: '',
        shipping_fee_additional: '',
        shopify_variant_id: '',
        variants: [],
      }];
    }
    changeVariantValue(newVariantValue);
  }
  const handleRemoveVariant = (target_index) => {
    const newVariantValue = variantValue ? { ...variantValue } : {};
    const new_variants = [];
    if (Array.isArray(newVariantValue.variants)) {
      newVariantValue.variants.forEach((variant, variant_index) => {
        if (variant_index !== target_index) {
          new_variants.push(variant);
        }
      });
    }
    newVariantValue.variants = new_variants;
    changeVariantValue(newVariantValue);
  }
  const handleAddSubvariant = (e, variant_key) => {
    e.preventDefault();
    const newVariantValue = variantValue ? { ...variantValue } : {};
    const found_variant_index = newVariantValue.variants.findIndex((variant) => variant.key === variant_key);
    if (Array.isArray(newVariantValue.variants[found_variant_index].subvariants)) {
      newVariantValue.variants[found_variant_index].subvariants.push({
        name: '',
        key: '',
        description: '',
        cart_notes: '',
        sku: '',
        price: '',
        price_original: '',
        shipping_fee: '',
        shipping_fee_additional: '',
        shopify_variant_id: '',
      });
    } else {
      newVariantValue.variants[found_variant_index].subvariants = [{
        name: '',
        key: '',
        description: '',
        cart_notes: '',
        sku: '',
        price: '',
        price_original: '',
        shipping_fee: '',
        shipping_fee_additional: '',
        shopify_variant_id: '',
      }];
    }
    changeVariantValue(newVariantValue);
  }
  const handleRemoveSubvariant = (variant_key, target_index) => {
    const newVariantValue = variantValue ? { ...variantValue } : {};
    const found_variant_index = newVariantValue.variants.findIndex((variant) => variant.key === variant_key);
    const new_variants = [];
    if (Array.isArray(newVariantValue.variants[found_variant_index].subvariants)) {
      newVariantValue.variants[found_variant_index].subvariants.forEach((variant, variant_index) => {
        if (variant_index !== target_index) {
          new_variants.push(variant);
        }
      });
    }
    newVariantValue.variants[found_variant_index].subvariants = new_variants;
    changeVariantValue(newVariantValue);
  }

  const renderSubvariantForm = (parent_variant_key, data, subvariant_index) => {
    const {
      name: name_value,
      key: key_value,
      cart_notes: cart_notes_value,
      description: description_value,
      sku: sku_value,
      price: price_value,
      price_original: price_original_value,
      shipping_fee: shipping_fee_value,
      shipping_fee_additional: shipping_fee_additional_value,
      shopify_variant_id: shopify_variant_id_value,
    } = data || {};
    const getInputID = (name) => `variant__${parent_variant_key}__${name}__${subvariant_index}`;
    const getInputName = (name) => `${parent_variant_key}__${name}__${subvariant_index}`;
    return (
      <div className={css.subvariantContainer}>
        <div className={css.variantTitle}>
          Subvariant #{subvariant_index + 1}
        </div>
        <div className={css.flexRow}>
          <div>
            <label htmlFor={getInputID('name')}>Name*</label>
            <input type="text" name={getInputName('name')} id={getInputID('name')} onChange={subvariantValueChange} value={name_value} />
          </div>
          <div>
            <label htmlFor={getInputID('key')}>
              Key*
            </label>
            <input type="text" name={getInputName('key')} id={getInputID('key')} onChange={subvariantValueChange} value={key_value} />
            <div className={css.details}>
              The unique identifier for variant. Don't change the ID after creating it. Use letters, numbers, dashes (-), or underscores (_), and no spaces.
            </div>
          </div>
        </div>
        <div className={css.singleRow}>
          <label htmlFor={getInputID('description')}>Description</label>
          <input type="text" name={getInputName('description')} id={getInputID('description')} onChange={subvariantValueChange} value={description_value} />
        </div>
        <div className={css.singleRow}>
          <label htmlFor={getInputID('cart_notes')}>Cart Notes</label>
          <input type="text" name={getInputName('cart_notes')} id={getInputID('cart_notes')} onChange={subvariantValueChange} value={cart_notes_value} />
        </div>
        <div className={css.flexRow}>
          <div>
            <label htmlFor={getInputID('sku')}>SKU</label>
            <input type="text" name={getInputName('sku')} id={getInputID('sku')} onChange={subvariantValueChange} value={sku_value} />
          </div>
          <div>
            <label htmlFor={getInputID('shopify_variant_id')}>Shopify Variant ID</label>
            <input type="text" name={getInputName('shopify_variant_id')} id={getInputID('shopify_variant_id')} onChange={subvariantValueChange} value={shopify_variant_id_value} />
          </div>
        </div>
        <div className={css.flexRow}>
          <div>
            <label htmlFor={getInputID('price')}>Price <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('price')} id={getInputID('price')} onChange={subvariantValueChange} value={price_value} />
          </div>
          <div>
            <label htmlFor={getInputID('price_original')}>Original Price <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('price_original')} id={getInputID('price_original')} onChange={subvariantValueChange} value={price_original_value} />
          </div>
          <div>
            <label htmlFor={getInputID('shipping_fee')}>Ship Fee <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('shipping_fee')} id={getInputID('shipping_fee')} onChange={subvariantValueChange} value={shipping_fee_value} />
          </div>
          <div>
            <label htmlFor={getInputID('shipping_fee_additional')}>Ship Fee Adtnl <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('shipping_fee_additional')} id={getInputID('shipping_fee_additional')} onChange={subvariantValueChange} value={shipping_fee_additional_value} />
          </div>
        </div>
        <div>
          <Button onClick={() => handleRemoveSubvariant(parent_variant_key, subvariant_index)}>
            Remove
          </Button>
        </div>
      </div>
    );
  }

  const renderVariantForm = (data, variant_index) => {
    const {
      name: name_value,
      key: key_value,
      cart_notes: cart_notes_value,
      description: description_value,
      sku: sku_value,
      price: price_value,
      price_original: price_original_value,
      shipping_fee: shipping_fee_value,
      shipping_fee_additional: shipping_fee_additional_value,
      shopify_variant_id: shopify_variant_id_value,
      subvariant_label: subvariant_label_value,
      subvariants = [],
    } = data || {};
    const getInputID = (name) => `variant_${name}_${variant_index}`;
    const getInputName = (name) => `${name}_${variant_index}`;
    return (
      <div className={css.variantContainer}>
        <div className={css.variantTitle}>
          Variant #{variant_index + 1}
        </div>
        <div className={css.flexRow}>
          <div>
            <label htmlFor={getInputID('name')}>Name*</label>
            <input type="text" name={getInputName('name')} id={getInputID('name')} onChange={variantValueChange} value={name_value} />
          </div>
          <div>
            <label htmlFor={getInputID('key')}>
              Key*
            </label>
            <input type="text" name={getInputName('key')} id={getInputID('key')} onChange={variantValueChange} value={key_value} />
            <div className={css.details}>
              The unique identifier for variant. Don't change the ID after creating it. Use letters, numbers, dashes (-), or underscores (_), and no spaces.
            </div>
          </div>
        </div>
        {
            Array.isArray(subvariants) && subvariants.length > 0
            && (
              <div className={css.flexRow}>
                <div>
                  <label htmlFor={getInputID('description')}>Description</label>
                  <input type="text" name={getInputName('description')} id={getInputID('description')} onChange={variantValueChange} value={description_value} />
                </div>
                <div>
                  <label htmlFor={getInputID('subvariant_label')}>Subvariant Label</label>
                  <input type="text" name={getInputName('subvariant_label')} id={getInputID('subvariant_label')} onChange={variantValueChange} value={subvariant_label_value} />
                </div>
              </div>
            )
        }
        {
            !Array.isArray(subvariants) || subvariants.length <= 0
            && (
              <div className={css.singleRow}>
                <label htmlFor={getInputID('description')}>Description</label>
                <input type="text" name={getInputName('description')} id={getInputID('description')} onChange={variantValueChange} value={description_value} />
              </div>
            )
        }
        {
            !Array.isArray(subvariants) || subvariants.length <= 0
            && (
              <div className={css.singleRow}>
                <label htmlFor={getInputID('cart_notes')}>Cart Notes</label>
                <input type="text" name={getInputName('cart_notes')} id={getInputID('cart_notes')} onChange={variantValueChange} value={cart_notes_value} />
              </div>
            )
        }
        <div className={css.flexRow}>
          <div>
            <label htmlFor={getInputID('sku')}>SKU</label>
            <input type="text" name={getInputName('sku')} id={getInputID('sku')} onChange={variantValueChange} value={sku_value} />
          </div>
          <div>
            <label htmlFor={getInputID('shopify_variant_id')}>Shopify Variant ID</label>
            <input type="text" name={getInputName('shopify_variant_id')} id={getInputID('shopify_variant_id')} onChange={variantValueChange} value={shopify_variant_id_value} />
          </div>
        </div>
        <div className={css.flexRow}>
          <div>
            <label htmlFor={getInputID('price')}>Price <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('price')} id={getInputID('price')} onChange={variantValueChange} value={price_value} />
          </div>
          <div>
            <label htmlFor={getInputID('price_original')}>Original Price <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('price_original')} id={getInputID('price_original')} onChange={variantValueChange} value={price_original_value} />
          </div>
          <div>
            <label htmlFor={getInputID('shipping_fee')}>Ship Fee <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('shipping_fee')} id={getInputID('shipping_fee')} onChange={variantValueChange} value={shipping_fee_value} />
          </div>
          <div>
            <label htmlFor={getInputID('shipping_fee_additional')}>Ship Fee Adtnl <span class={css.details}>(pennies)</span></label>
            <input type="text" name={getInputName('shipping_fee_additional')} id={getInputID('shipping_fee_additional')} onChange={variantValueChange} value={shipping_fee_additional_value} />
          </div>
        </div>
        <div>
          <label htmlFor="variant_variants">Subvariants</label>
          {
            Array.isArray(subvariants) && subvariants.map((subvariant, subvariant_index) => renderSubvariantForm(key_value, subvariant, subvariant_index))
          }
        </div>
        <div className={css.flexRow}>
          <div>
            <Button onClick={(e) => handleAddSubvariant(e, key_value)}>
                Add Subvariant
            </Button>
          </div>
          <div>
            <Button onClick={() => handleRemoveVariant(variant_index)}>
              Remove
            </Button>
          </div>
          
        </div>
      </div>
    );
  }
  const renderVariantsForm = () => {
    const {
      name: name_value,
      key: key_value,
      description: description_value,
      variants,
    } = variantValue || {};
    if (!variantValue) {
      return (
        <div className={css.variantsContainer}>
          <Button onClick={() => changeVariantValue({
            name: '',
            description: '',
            variants: [{
              name: '',
              key: '',
              cart_notes: '',
              description: '',
              sku: '',
              price: '',
              price_original: '',
              shipping_fee: '',
              shipping_fee_additional: '',
            }],
          })}>
            Create Variants
          </Button>
        </div>
      )
    }
    return (
      <div className={css.variantsContainer}>
        <div className={css.flexRow}>
          <div>
            <label htmlFor="variants_name">Name</label>
            <input type="text" name="name" id="variants_name" onChange={variantsValueChange} value={name_value} />
          </div>
          <div>
            <label htmlFor="variants_description">Description</label>
            <input type="text" name="description" id="variants_description" onChange={variantsValueChange} value={description_value} />
            {/*
              <label htmlFor="variants_key">Key</label>
              <input type="text" name="key" id="variants_key" onChange={variantsValueChange} value={key_value} />
            */}
          </div>
        </div>
        {/*
        <div className={css.singleRow}>
          <label htmlFor="variants_description">Description</label>
          <input type="text" name="description" id="variants_description" onChange={variantsValueChange} value={description_value} />
        </div>
        */}
        <div>
          <label htmlFor="variant_variants">Variants</label>
          {
            Array.isArray(variants) && variants.map((variant, variant_index) => renderVariantForm(variant, variant_index))
          }
        </div>
        <div className={css.flexRow}>
          <div>
            <Button onClick={(e) => handleAddVariant(e)}>
                Add Variant
            </Button>
          </div>
          {
            variantValue && (
              <div>
                <Button onClick={() => changeVariantValue('')}>
                  Remove Variants From Listing
                </Button>
              </div>
            )
          }
        </div>
      </div>
    )
  }
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      {renderVariantsForm()}
      {isTextarea ? <ExpandingTextarea {...inputProps} style={{ visibility: 'hidden' }} /> : <input {...inputProps} style={{ visibility: 'hidden' }} />}
      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

FieldVariantsInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldVariantsInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldVariantsInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldVariantsInputComponent} {...this.props} />;
  }
}

export default FieldVariantsInput;
