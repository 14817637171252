import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
// import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  bulkUpdateOrders,
} from '../../util/api';

// ================ Action types ================ //

export const UPDATE_ORDERS_REQUEST = 'app/OrdersStatusImportModal/UPDATE_ORDERS_REQUEST';
export const UPDATE_ORDERS_SUCCESS = 'app/OrdersStatusImportModal/UPDATE_ORDERS_SUCCESS';
export const UPDATE_ORDERS_ERROR = 'app/OrdersStatusImportModal/UPDATE_ORDERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateOrdersInProgress: false,
  updateOrdersError: null,
  updatedRows: 0,
};

export default function ordersStatusImportModalReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ORDERS_REQUEST:
      return { ...state, updateOrdersInProgress: true, updateOrdersError: null };
    case UPDATE_ORDERS_SUCCESS: {
      // const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        updateOrdersInProgress: false,
        updatedRows: payload.updatedRows || 0,
      };
    }
    case UPDATE_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, updateOrdersInProgress: false, updateOrdersError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const updateOrdersRequest = () => ({ type: UPDATE_ORDERS_REQUEST });
const updateOrdersSuccess = response => ({
  type: UPDATE_ORDERS_SUCCESS,
  payload: response,
});
const updateOrdersError = e => ({
  type: UPDATE_ORDERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const requestBulkUpdateOrderStatus = params => (dispatch, getState, sdk) => {
  dispatch(updateOrdersRequest());
  const {
    orders,
  } = params || {};

  return bulkUpdateOrders({ orders })
    .then(response => {
      if (!response.success) {
        return Promise.reject(new Error(response?.data?.error?.message || 'Could not fetch orders'));
      }
      // console.log('response', response);
      // dispatch(addMarketplaceEntities(response));
      dispatch(updateOrdersSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(updateOrdersError(storableError(e)));
      throw e;
    });
};

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(updateOrdersRequest());
  const { page = 1 } = parse(search);

  return getOrdersForCustomer({ page, perPage: INBOX_PAGE_SIZE })
    .then(response => {
      if (!response.success) {
        return Promise.reject(new Error(response?.data?.error?.message || 'Could not fetch orders'));
      }
      // dispatch(addMarketplaceEntities(response));
      dispatch(updateOrdersSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(updateOrdersError(storableError(e)));
      throw e;
    });
};
