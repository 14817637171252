import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { fetchCart } from '../../containers/CartListingPage/CartListingPage.duck';


export const loadData = (params, search, config) => dispatch => {
  const pageId = params.pageId;
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;
  // return dispatch(fetchPageAssets(pageAsset, hasFallbackContent));
  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, hasFallbackContent)),
    dispatch(fetchCart(config)),
  ]);
};
