import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
// import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getOrderForCustomer,
} from '../../util/api';

// ================ Action types ================ //

export const FETCH_ORDER_REQUEST = 'app/OrderDetailPage/FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'app/OrderDetailPage/FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_ERROR = 'app/OrderDetailPage/FETCH_ORDER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchOrderInProgress: false,
  fetchOrderError: null,
  order: null,
};

export default function orderDetailPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDER_REQUEST:
      return { ...state, fetchOrderInProgress: true, fetchOrderError: null };
    case FETCH_ORDER_SUCCESS: {
      // const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchOrderInProgress: false,
        order: payload.data,
      };
    }
    case FETCH_ORDER_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchOrderInProgress: false, fetchOrderError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrderRequest = () => ({ type: FETCH_ORDER_REQUEST });
const fetchOrderSuccess = response => ({
  type: FETCH_ORDER_SUCCESS,
  payload: response,
});
const fetchOrderError = e => ({
  type: FETCH_ORDER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;
const sample_order = {
  id: 24,
  stid: null,
  recipient_first_name: "SWC",
  recipient_last_name: "2",
  recipient_name: "SWC2",
  recipient_address_1: "130 West 42nd Street",
  recipient_postal_code: "10036",
  recipient_state: "NY",
  recipient_email: "sean.collins@futurenet.com",
  recipient_phone: null,
  recipient_city: "New York",
  recipient_country: "US",
  status: null,
  stripe_status: null,
  stripe_payment_intent_id: "pi_3OcGjgH2N5etiYIf1jgvjqK0",
  created_at: "2024-01-25T08:24:05.000Z",
  total_price: "80500.00",
  shipping_price: "2000.00",
  subtotal_price: "78500.00",
  user_id: "65aef294-7f57-48e5-a89f-2737169ff88b",
  code: "ce066a43fc3e9b6137c2e52b518f8b",
  invoice_no: "f8b518f8b",
  items: [
      {
          id: 28,
          stid: "659596df-151f-440e-8714-68598f329ff0",
          name: "Subpod Grow Bundle",
          quantity: 1,
          order_id: 24,
          created_at: "2024-01-25T08:24:05.000Z",
          stripe_payment_intent_id: "pi_3OcGjgH2N5etiYIf1jgvjqK0",
          price: "36500.00",
          shipping_price: "1000.00",
          author_id: "658b5d0b-e182-4329-8fe4-322fc6c0cf35",
          author_name: "Subpod",
          img_url: "https://sharetribe.imgix.net/657b658b-9051-41b1-95b9-b267c4f039a9/6595970f-3bcf-42c8-9a33-ccf6ab49040a?auto=format&crop=edges&fit=crop&h=600&w=800&s=1e249ef4838bbcea110c75a5f1178657"
      },
      {
          id: 29,
          stid: "65959774-cf0d-49a6-aa59-edf91d7acb0f",
          name: "Composter",
          quantity: 2,
          order_id: 24,
          created_at: "2024-01-25T08:24:05.000Z",
          stripe_payment_intent_id: "pi_3OcGjgH2N5etiYIf1jgvjqK0",
          price: "22000.00",
          shipping_price: "1000.00",
          author_id: "658b5d0b-e182-4329-8fe4-322fc6c0cf35",
          author_name: "Subpod",
          img_url: "https://sharetribe.imgix.net/657b658b-9051-41b1-95b9-b267c4f039a9/65959793-cadc-4b5e-8fbb-387d6b58001e?auto=format&crop=edges&fit=crop&h=600&w=800&s=b33653e4b53dd044d4c1a3e239bbe12e"
      }
  ]
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrderRequest());
  const {
    code,
  } = params || {};
  const { page = 1 } = parse(search);

  // dispatch(fetchOrderSuccess({ data: sample_order }));
  // return Promise.resolve();;
  return getOrderForCustomer(code)
    .then(response => {
      // console.log('response', response);
      if (!response.success) {
        return Promise.reject(new Error(response?.data?.error?.message || 'Could not fetch orders'));
      }
      // dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrderSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrderError(storableError(e)));
      throw e;
    });
};
