import React, { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import { toast } from 'react-toastify';
import {
  // arrayOf,
  bool,
  func,
  // number,
  // oneOf,
  shape,
  string,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
} from '@mui/material';

// import moment from 'moment-timezone';

import {
  // FormattedMessage,
  injectIntl,
  intlShape,
} from '../../util/reactIntl';
import {
  propTypes,
} from '../../util/types';

// import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import { isScrollingDisabled } from '../../ducks/ui.duck';
import {
  Button,
  // IconSpinner,
} from '../../components';

import css from './ImportStockModal.module.css';

const CSVSection = (props) => {
  const {
    onStockUpdate = () => {},
    onQueryOwnListings = () => {},
    closeModal = () => {},
  } = props || {};
  return (
    <div>     
      <p>Each row will import in draft status.</p>
      <Importer
        dataHandler={async (rows, { startIndex }) => {
          // required, may be called several times
          // receives a list of parsed objects based on defined fields and user column mapping;
          // (if this callback returns a promise, the widget will wait for it before parsing more data)
          // for (row of rows) {
          //   await myAppMethod(row);
          // }
          // console.log('rows', rows);
          const rowCanBeImported = (si) => {
            const {
              sku,
              stockUpdate,
            } = si;
            return sku && parseInt(stockUpdate, 10) >= 0;
          };
          const filtered_rows = rows.filter(fi => rowCanBeImported(fi));
          const row_vals = [];
          for (let i = 0; i < filtered_rows.length; i++) {
            const row = filtered_rows[i];
            const {
              sku,
              stockUpdate,
            } = row || {};
            const vals = {
              sku,
            };
            if (parseInt(stockUpdate, 10)) {
              vals.stockUpdate = {
                oldTotal: null,
                newTotal: parseInt(stockUpdate, 10)
              };
            }
            row_vals.push(vals);
          }
          await onStockUpdate(row_vals)
            .then(() => {
              toast.success(`Imported row ${i + 1}`);
              return Promise.resolve();
            })
            .catch((err) => {
              toast.error(`Error importing row ${i + 1}: ${err.message}`)
            });
          const num_not_imported = rows.filter((r, i) => !rowCanBeImported(r, i)).length;
          if (num_not_imported) {
            const error_array = [];
            toast.error(`Skipped ${num_not_imported} rows due to ${error_array.join(' and ')}}`);
          }
          await onQueryOwnListings({}, '', config)
            .then(() => {
              console.log('queried own listings');
            })
            .catch((err) => {
              toast.error(`Error querying listings: ${err.message}`);
              console.log(err.message);
            });
        }}
        defaultNoHeader={true} // optional, keeps "data has headers" checkbox off by default
        restartable={false} // optional, lets user choose to upload another file when import is complete
        onStart={({ file, preview, fields, columnFields }) => {
          // optional, invoked when user has mapped columns and started import
          // prepMyAppForIncomingData();
          // console.log(preview, fields, columnFields);
        }}
        onComplete={({ file, preview, fields, columnFields }) => {
          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
          // showMyAppToastNotification();
          toast.success('Imported successfully');
        }}
        onClose={({ file, preview, fields, columnFields }) => {
          // optional, if this is specified the user will see a "Finish" button after import is done,
          // which will call this when clicked
          closeModal();
        }}

        // CSV options passed directly to PapaParse if specified:
        // delimiter={...}
        // newline={...}
        // quoteChar={...}
        // escapeChar={...}
        // comments={...}
        // skipEmptyLines={...}
        // delimitersToGuess={...}
        // chunkSize={...} // defaults to 10000
        // encoding={...} // defaults to utf-8, see FileReader API
      >
        <ImporterField name="sku" label="SKU" />
        <ImporterField name="stockUpdate" label="Stock Update" optional />
      </Importer>
    </div>
  );
};

export const ImportStockModalComponent = props => {
  const {
    // currentUser,
    intl,
    // params,
    onStockUpdate,
    onQueryOwnListings,
  } = props;
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle>
          Bulk Import Stock From CSV
        </DialogTitle>
        <DialogContent classes={{ root: css.root }}>
          <Grid container spacing={3} className={css.gridContainer}>
            <Grid item xs={12}>
              <CSVSection
                onStockUpdate={onStockUpdate}
                onQueryOwnListings={onQueryOwnListings}
                closeModal={closeModal}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={3} className={css.gridContainer}>
            <Grid item xs={8} />
            <Grid item xs={4}>
              <Button
                className={css.submitButton}
                onClick={() => {
                  setOpen(false);
                }}
                // ready={submitReady}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {
        !open && (
          <Button
            className={css.reportsButton}
            onClick={() => setOpen(true)}
          >
            Bulk Import Stock From CSV
          </Button>
        )
      }
    </>
  );
};

ImportStockModalComponent.defaultProps = {
  currentUser: null,
};

ImportStockModalComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  onStockUpdate: func.isRequired,
  onQueryOwnListings: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const ImportStockModal = compose(
  connect(mapStateToProps),
  injectIntl
)(ImportStockModalComponent);

export default ImportStockModal;
