import pick from 'lodash/pick';
const { types } = require('sharetribe-flex-sdk');
// import moment from 'moment';
// import config from '../../config';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  // transactionLineItems,
  showCart,
  addItemsToCart,
  removeItemsFromCart,
  updateItemInCart,
  createPaymentIntent,
  addShippingToCart,
  clearCart,
  subscribeToNewsletter,
} from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { get } from 'lodash';
const { Money } = types;
// import { TRANSITION_ENQUIRE } from '../../util/transaction';
// import {
//   LISTING_PAGE_DRAFT_VARIANT,
//   LISTING_PAGE_PENDING_APPROVAL_VARIANT,
// } from '../../util/urlHelpers';
// import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CartListingPage/SET_INITIAL_VALUES';

export const SHOW_CART_REQUEST = 'app/CartListingPage/SHOW_CART_REQUEST';
export const SHOW_CART_SUCCESS = 'app/CartListingPage/SHOW_CART_SUCCESS';
export const SHOW_CART_ERROR = 'app/CartListingPage/SHOW_CART_ERROR';

export const ADD_SHIPPING_TO_CART_REQUEST = 'app/CartListingPage/ADD_SHIPPING_TO_CART_REQUEST';
export const ADD_SHIPPING_TO_CART_SUCCESS = 'app/CartListingPage/ADD_SHIPPING_TO_CART_SUCCESS';
export const ADD_SHIPPING_TO_CART_ERROR = 'app/CartListingPage/ADD_SHIPPING_TO_CART_ERROR';

export const ADD_TO_CART_REQUEST = 'app/CartListingPage/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'app/CartListingPage/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'app/CartListingPage/ADD_TO_CART_ERROR';

export const UPDATE_CART_REQUEST = 'app/CartListingPage/UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'app/CartListingPage/UPDATE_CART_SUCCESS';
export const UPDATE_CART_ERROR = 'app/CartListingPage/UPDATE_CART_ERROR';

export const REMOVE_FROM_CART_REQUEST = 'app/CartListingPage/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'app/CartListingPage/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_ERROR = 'app/CartListingPage/REMOVE_FROM_CART_ERROR';

export const CLEAR_CART_REQUEST = 'app/CartListingPage/CLEAR_CART_REQUEST';
export const CLEAR_CART_SUCCESS = 'app/CartListingPage/CLEAR_CART_SUCCESS';
export const CLEAR_CART_ERROR = 'app/CartListingPage/CLEAR_CART_ERROR';

export const CLEAR_STORAGE = 'app/CartListingPage/CLEAR_STORAGE';

// ================ Reducer ================ //

const initialState = {
  showCartError: null,
  showCartInProgress: false,
  cartData: null,
  addToCartInProgress: false,
  addToCartError: null,
  removeFromCartInProgress: false,
  removeFromCartError: null,
  updateCartInProgress: false,
  updateCartError: null,
  shippingData: null,
  addShippingToCartInProgress: false,
  addShippingToCartError: null,
  clearCartInProgress: false,
  clearCartError: null,
};

const cartListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_CART_REQUEST:
      return { ...state, showCartInProgress: payload, showCartError: null };
    case SHOW_CART_SUCCESS:
      return {
        ...state,
        showCartInProgress: false,
        cartData: payload,
      };
    case SHOW_CART_ERROR:
      return { ...state, showCartError: payload };

    case ADD_TO_CART_REQUEST:
      return { ...state, addToCartInProgress: true, addToCartError: null };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartInProgress: false,
        cartData: payload,
      };
    case ADD_TO_CART_ERROR:
      return { ...state, addToCartInProgress: false, addToCartError: payload };

    case ADD_SHIPPING_TO_CART_REQUEST:
      return { ...state, addShippingToCartInProgress: true, addShippingToCartError: null };
    case ADD_SHIPPING_TO_CART_SUCCESS:
      return {
        ...state,
        addShippingToCartInProgress: false,
        shippingData: payload,
      };
    case ADD_SHIPPING_TO_CART_ERROR:
      return { ...state, addShippingToCartInProgress: false, addShippingToCartError: payload };

    case REMOVE_FROM_CART_REQUEST:
      return { ...state, removeFromCartInProgress: true, removeFromCartError: null };
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        removeFromCartInProgress: false,
        cartData: payload,
      };
    case REMOVE_FROM_CART_ERROR:
      return { ...state, removeFromCartInProgress: false, removeFromCartError: payload };

    case UPDATE_CART_REQUEST:
      return { ...state, updateCartInProgress: true, updateCartError: null };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        updateCartInProgress: false,
        cartData: payload,
      };
    case UPDATE_CART_ERROR:
      return { ...state, updateCartInProgress: false, updateCartError: payload };

    case CLEAR_CART_REQUEST:
      return { ...state, clearCartInProgress: true, clearCartError: null };
    case CLEAR_CART_SUCCESS:
      return {
        ...state,
        clearCartInProgress: false,
        cartData: payload.cartData,
        shippingData: payload.shippingData,
      };
    case CLEAR_CART_ERROR:
      return { ...state, clearCartInProgress: false, clearCartError: payload };
  
    default:
      return state;
  }
};

export default cartListingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showCartRequest = () => ({
  type: SHOW_CART_REQUEST,
});

export const showCartSuccess = e => ({
  type: SHOW_CART_SUCCESS,
  payload: e,
});

export const showCartError = e => ({
  type: SHOW_CART_ERROR,
  error: true,
  payload: e,
});

export const addToCartRequest = () => ({ type: ADD_TO_CART_REQUEST });
export const addToCartSuccess = (e) => ({ type: ADD_TO_CART_SUCCESS, payload: e });
export const addToCartError = e => ({ type: ADD_TO_CART_ERROR, error: true, payload: e });

export const removeFromCartRequest = () => ({ type: REMOVE_FROM_CART_REQUEST });
export const removeFromCartSuccess = () => ({ type: REMOVE_FROM_CART_SUCCESS });
export const removeFromCartError = e => ({ type: REMOVE_FROM_CART_ERROR, error: true, payload: e });

export const updateCartRequest = () => ({ type: UPDATE_CART_REQUEST });
export const updateCartSuccess = (e) => ({ type: UPDATE_CART_SUCCESS, payload: e });
export const updateCartError = e => ({ type: UPDATE_CART_ERROR, error: true, payload: e });

export const addShippingToCartRequest = () => ({ type: ADD_SHIPPING_TO_CART_REQUEST });
export const addShippingToCartSuccess = (e) => ({ type: ADD_SHIPPING_TO_CART_SUCCESS, payload: e });
export const addShippingToCartError = e => ({ type: ADD_SHIPPING_TO_CART_ERROR, error: true, payload: e });

export const clearCartRequest = () => ({ type: CLEAR_CART_REQUEST });
export const clearCartSuccess = (e) => ({ type: CLEAR_CART_SUCCESS, payload: e });
export const clearCartError = e => ({ type: CLEAR_CART_ERROR, error: true, payload: e });

export const clearStorage = () => ({ type: CLEAR_STORAGE });

// ================ Thunks ================ //

/*
export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};
*/

export const getDataFromVariants = (listing, orderData) => {
  const publicData = listing?.attributes?.publicData;
  let unitPrice = listing?.attributes?.price;
  const currency = unitPrice.currency;
  let {
    shippingPriceInSubunitsOneItem,
    shippingPriceInSubunitsAdditionalItems,
  } = publicData || {};

  const listing_variants_json = publicData?.variants;
  const listing_variants_obj = listing_variants_json ? JSON.parse(listing_variants_json) : [];
  // console.log('getDataFromVariants orderData', orderData);
  const {
    variant: order_variant,
  } = orderData || {};
  if (
    order_variant?.value
    && listing_variants_obj
  ) {
    const {
      variants: variant_options,
    } = listing_variants_obj || {};
    if (Array.isArray(variant_options)) {
      const found_option = variant_options.find(vo => vo.key === order_variant?.value);
      if (found_option) {
        const {
          price: variant_price,
          shipping_fee: variant_shipping_fee,
          shipping_fee_additional: variant_shipping_fee_additional,
          subvariants,
        } = found_option || {};
        if (variant_price) {
          unitPrice = new Money(parseFloat(variant_price || 0), currency);
        }
        if (variant_shipping_fee) {
          shippingPriceInSubunitsOneItem = parseFloat(variant_shipping_fee || 0);
        }
        if (variant_shipping_fee_additional) {
          shippingPriceInSubunitsAdditionalItems = parseFloat(variant_shipping_fee_additional || 0);
        }
        if (Array.isArray(subvariants) && order_variant?.subvariant_value) {
          const found_sub_option = subvariants.find(vo => vo.key === order_variant?.subvariant_value);
          if (found_sub_option) {
            const {
              price: subvariant_price,
              shipping_fee: subvariant_shipping_fee,
              shipping_fee_additional: subvariant_shipping_fee_additional,
            } = found_sub_option || {};
            if (variant_price) {
              unitPrice = new Money(parseFloat(subvariant_price || 0), currency);
            }
            if (variant_shipping_fee) {
              shippingPriceInSubunitsOneItem = parseFloat(subvariant_shipping_fee || 0);
            }
            if (variant_shipping_fee_additional) {
              shippingPriceInSubunitsAdditionalItems = parseFloat(subvariant_shipping_fee_additional || 0);
            }
          }
        }
      }
    }
    /*
    listing_variants.forEach((listing_variant) => {
      if (Object.keys(order_variants).includes(listing_variant.key)) {
        const {
          variants: variant_options,
        } = listing_variant || {};
        if (Array.isArray(variant_options)) {
          const found_option = variant_options.find(vo => vo.key === order_variants[listing_variant.key].value);
          if (found_option) {
            const {
              price: variant_price,
              shipping_fee: variant_shipping_fee,
              shipping_fee_additional: variant_shipping_fee_additional,
              subvariants,
            } = found_option || {};
            if (variant_price) {
              unitPrice = new Money(parseFloat(variant_price || 0), currency);
            }
            if (variant_shipping_fee) {
              shippingPriceInSubunitsOneItem = parseFloat(variant_shipping_fee || 0);
            }
            if (variant_shipping_fee_additional) {
              shippingPriceInSubunitsAdditionalItems = parseFloat(variant_shipping_fee_additional || 0);
            }
            if (Array.isArray(subvariants) && order_variants[listing_variant.key]?.subvariant?.value) {
              const found_sub_option = subvariants.find(vo => vo.key === order_variants[listing_variant.key].subvariant.value);
              if (found_sub_option) {
                const {
                  price: subvariant_price,
                  shipping_fee: subvariant_shipping_fee,
                  shipping_fee_additional: subvariant_shipping_fee_additional,
                } = found_sub_option || {};
                if (variant_price) {
                  unitPrice = new Money(parseFloat(subvariant_price || 0), currency);
                }
                if (variant_shipping_fee) {
                  shippingPriceInSubunitsOneItem = parseFloat(subvariant_shipping_fee || 0);
                }
                if (variant_shipping_fee_additional) {
                  shippingPriceInSubunitsAdditionalItems = parseFloat(subvariant_shipping_fee_additional || 0);
                }
              }
            }
          }
        }
      }
    });
    */
  }
  return {
    unitPrice,
    shippingPriceInSubunitsOneItem,
    shippingPriceInSubunitsAdditionalItems,
  };
}

const getItemData = (listing, config, sdk) => {
  const {
    id: listingId,
  } = listing || {};
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config?.layout?.listingImage || {};
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    id: new UUID(listingId),
    include: ['images','author.displayName','author.profileImage','currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };
  // console.log('params', params);

  const show = sdk.listings.show(params);

  return show
    .then(response => {
      // console.log('response client', response.data.data.relationships);
      const data = denormalisedResponseEntities(response);
      // console.log('data', data);
      const item_data = Array.isArray(data) ? data[0] : data;
      const {
        unitPrice,
        shippingPriceInSubunitsOneItem,
        shippingPriceInSubunitsAdditionalItems,
      } = getDataFromVariants(item_data, listing);
      item_data.attributes.price = unitPrice;
      item_data.attributes.publicData.shippingPriceInSubunitsOneItem = shippingPriceInSubunitsOneItem;
      item_data.attributes.publicData.shippingPriceInSubunitsAdditionalItems = shippingPriceInSubunitsAdditionalItems;
      return Promise.resolve(item_data);
    })
    .catch(e => {
      console.log('error', e.message);
    });
}

const sampleCartData = {
  items: [
    {
      deliveryMethod: 'shipping',
      id: '659596df-151f-440e-8714-68598f329ff0',
      item_data: {
        attributes: {
          title: 'Subpod Grow Bundle',
          description: 'The Subpod Grow bundle is a garden bed, worm farm, compost bin and garden seat all rolled into one!',
          price: new Money(36500, 'USD'),
          publicData: {
            variants: `[{"name":"Size","key":"size","description":"Description","variants":[{"name":"Small","key":"small","sku":"12345","price":1000,"shipping_fee":500,"shipping_fee_additional":600},{"name":"Medium","key":"medium","sku":"12346","price":1100,"shipping_fee":700,"shipping_fee_additional":800},{"name":"Large","key":"large","sku":"12347","price":1200,"shipping_fee":900,"shipping_fee_additional":1000},{"name":"Extra Large","key":"xlarge","description":"","sku":"1234568","price":"1300","shipping_fee":"1100","shipping_fee_additional":"1200"},{"name":"Extra Extra Large","key":"xxlarge","description":"","sku":"1251251","price":"1400","shipping_fee":"1200","shipping_fee_additional":"1300"}]}]`,
          }
        },
        author: {
          id: {
            uuid: '65aef294-7f57-48e5-a89f-2737169ff88b',
            _sdkType: 'UUID',
          },
          attributes: {
            profile: {
              displayName: 'Subpod',
            },
          },
        },
        id: {
          _sdkType: 'UUID',
          uuid: '659596df-151f-440e-8714-68598f329ff0'
        },
        images: [
          {
            attributes: {
              variants: {
                'scaled-large': {
                  height: 684,
                  name: 'scaled-large',
                  url: 'https://sharetribe.imgix.net/657b658b-9051-41b1-95b9-b267c4f039a9/65b8048a-be24-4457-858e-91383d20fb7b?auto=format&fit=clip&h=1024&w=1024&s=82abd5455af52218a68d33a789778773',
                  width: 1024,
                },
              },
            },
            id: {
              uuid: '65b8048a-be24-4457-858e-91383d20fb7b',
              _sdkType: 'UUID',
            },
            type: 'image',
          },
        ],
        type: 'listing',
      },
      price: 365,
      quantity: 1,
      shipping_price: 700,
      variants: {
        size: {
          value: 'small',
          sku: '12345',
        },
      },
    },
  ],
  totalPrice: 36500,
};

const sampleShippingData = {
  recipientFirstName: 'Marc',
  recipientLastName: 'Precilla',
  recipientEmail: 'marc@worklab.io',
  recipientName: 'Marc Precilla',
  recipientAddressLine1: '125 Main St',
  recipientPostal: '91214',
  recipientCity: 'Los Angeles',
  recipientState: 'California',
  recipientCountry: 'US',
};

export const fetchCart = (config) => (dispatch, getState, sdk) => {
  // console.log('getState', getState().CartListingPage?.cartData);
  dispatch(showCartRequest());
  // dispatch(showCartSuccess(sampleCartData));
  // dispatch(addShippingToCartSuccess(sampleShippingData));
  // return Promise.resolve();
  return showCart()
    .then((response) => {
      if (!response) {
        return Promise.reject(new Error('Could not fetch cart. No response.'));
      }
      const newCartData = response.cart;
      const promise_array = [];
      if (Array.isArray(newCartData.items)) {
        for (let i = 0; i < newCartData.items.length; i++) {
          const current_new_cart_item = { ...newCartData.items[i] };
          promise_array.push(getItemData(current_new_cart_item, config, sdk)
            .then((item_data) => {
              if (newCartData.items[i]) {
                newCartData.items[i].item_data = item_data;
              }
              return Promise.resolve();
            }));
        }
      }
      return Promise.all(promise_array).then(() => {
        // console.log('fetchCart', newCartData);
        dispatch(showCartSuccess(newCartData));
        dispatch(addShippingToCartSuccess(response.cart_shipping));
      });
    })
    .catch(e => {
      dispatch(showCartError(storableError(e)));
      console.log('error', e.message);
      log.error(e, 'show-cart-failed');
    });
};

export const addToCart = (orderData) => dispatch => {
  dispatch(addToCartRequest());
  // console.log('orderData', orderData);
  // dispatch(addToCartSuccess(sampleCartData));
  // return Promise.resolve();
  return addItemsToCart(orderData)
    .then(response => {
      const newCartData = response.updatedCart;
      dispatch(addToCartSuccess(newCartData));
      // console.log('response', response);
    })
    .catch(e => {
      dispatch(addToCartError(storableError(e)));
      console.log('error', e.message);
      log.error(e, 'show-cart-failed');
    });
};

export const removeFromCart = (orderData) => dispatch => {
  dispatch(removeFromCartRequest());
  removeItemsFromCart(orderData)
    .then(response => {
      const newCartData = response.data;
      dispatch(removeFromCartSuccess(newCartData));
      // console.log('response', response);
    })
    .catch(e => {
      dispatch(removeFromCartError(storableError(e)));
      console.log('error', e.message);
      log.error(e, 'show-cart-failed');
    });
};

export const updateCart = (orderData, is_logged_in, config) => (dispatch, getState, sdk) => {
  dispatch(updateCartRequest());
  // console.log('orderData', orderData);
  // dispatch(updateCartSuccess(sampleCartData));
  // return Promise.resolve();
  updateItemInCart(orderData)
    .then(async (response) => {
      const newCartData = response.updatedCart;
      if (Array.isArray(newCartData.items)) {
        for (let i = 0; i < newCartData.items.length; i++) {
          const item_data = await getItemData(newCartData.items[i], config, sdk);
          newCartData.items[i].item_data = item_data;
        }
      }
      dispatch(updateCartSuccess(newCartData));
      if (
        !is_logged_in &&
        (!Array.isArray(newCartData?.items) || newCartData?.items.length === 0)
      ) {
        dispatch(clearStorage());
      }
      // console.log('response', response);
    })
    .catch(e => {
      dispatch(updateCartError(storableError(e)));
      console.log('error', e.message);
      log.error(e, 'show-cart-failed');
    });
};

export const updateShippingToCart = (shippingData) => dispatch => {
  dispatch(addShippingToCartRequest());
  return addShippingToCart({ shipping_data: shippingData })
    .then(response => {
      const newShippingData = response?.data?.shipping_data;
      dispatch(addShippingToCartSuccess(newShippingData));
    })
    .catch(e => {
      dispatch(addShippingToCartError(storableError(e)));
      console.log('error', e.message);
      log.error(e, 'add-shipping-cart-failed');
    });
};

export const doClearCart = () => dispatch => {
  dispatch(clearCartRequest());
  return clearCart()
    .then(response => {
      const newCartData = response?.data?.cart;
      const newShippingData = response?.data?.cart_shipping;
      dispatch(clearCartSuccess({
        cartData: newCartData,
        shippingData: newShippingData,
      }));
    })
    .catch(e => {
      dispatch(clearCartError(storableError(e)));
      console.log('error', e.message);
      log.error(e, 'clear-cart-failed');
    });
};

export const doCreatePaymentIntent = (orderData) => dispatch => {
  return createPaymentIntent(orderData);
};

export const doSubscribeToNewsletter = (params) => dispatch => {
  console.log('yo', params);
  return subscribeToNewsletter(params);
}

export const loadData = (params, search, config) => {
  console.log('loading data for cart page');
  /*
  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  if (config.enableAvailability) {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchTimeSlots(listingId)),
      dispatch(showRelatedListings(listingId)),
    ]);
  }
  */

  return fetchCart(config);
};
