import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemShippingFeeMaybe = props => {
  const { lineItems, intl } = props;

  let shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );
  const newSearchInProgresshippingFeeLineItem = new Money(0, shippingFeeLineItem?.lineTotal?.currency || 'USD');

  return shippingFeeLineItem || true ? (
    <div className={css.lineItemHighlighted}>
      <span className={css.itemLabel}>
        {/* <FormattedMessage id="OrderBreakdown.shippingFee" /> */}
        Free Shipping
      </span>
      <span className={css.itemValue}>{formatMoney(intl, newSearchInProgresshippingFeeLineItem)}</span>
    </div>
  ) : null;
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
