import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string, bool, func } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName, PromiseButton } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';

const Review = props => {
  const {
    review,
    intl,
    isOwnListing,
    onToggleReview,
  } = props;

  const date = review.attributes.createdAt;
  const status = review?.attributes?.status;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });
  const toggleReviewVisibility = () => onToggleReview(review?.id)
    .then(() => {
      console.log('all done');
    });
  const is_visible = status !== 'hidden';
  if (!isOwnListing && !is_visible) {
    return null;
  }

  return (
    <div className={is_visible ? css.review : css.hiddenReview}>
      <Avatar className={css.avatar} user={review.author} />
      <div>
        <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>{review.attributes.content}</p>
        <p className={css.reviewInfo}>
          <UserDisplayName user={review.author} intl={intl} />
          <span className={css.separator}>•</span>
          {dateString}
          <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
          {
            isOwnListing
            && (
              <>
                <span className={css.desktopSeparator}>•</span>
                <span>
                  <PromiseButton
                    doPromise={() => toggleReviewVisibility()}
                    className={css.cta}
                  >
                      {status === 'hidden' ? 'show' : 'hide'}
                  </PromiseButton>
                </span>
              </>
            )
          }
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const {
    className,
    rootClassName,
    reviews,
    intl,
    isOwnListing,
    onToggleReview,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review
              review={r}
              intl={intl}
              isOwnListing={isOwnListing}
              onToggleReview={onToggleReview}
            />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
  isOwnListing: false,
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),
  isOwnListing: bool,
  onToggleReview: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
