import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
// import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getOrdersForCustomer,
} from '../../util/api';

// ================ Action types ================ //

export const FETCH_ORDERS_REQUEST = 'app/OrdersPage/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'app/OrdersPage/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'app/OrdersPage/FETCH_ORDERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchOrdersInProgress: false,
  fetchOrdersError: null,
  orders: [],
  pagination: null,
};

export default function ordersPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_REQUEST:
      return { ...state, fetchOrdersInProgress: true, fetchOrdersError: null };
    case FETCH_ORDERS_SUCCESS: {
      // const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchOrdersInProgress: false,
        orders: payload.data,
        pagination: payload.meta,
      };
    }
    case FETCH_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchOrdersInProgress: false, fetchOrdersError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersRequest = () => ({ type: FETCH_ORDERS_REQUEST });
const fetchOrdersSuccess = response => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: response,
});
const fetchOrdersError = e => ({
  type: FETCH_ORDERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersRequest());
  const { page = 1 } = parse(search);

  return getOrdersForCustomer({ page, perPage: INBOX_PAGE_SIZE })
    .then(response => {
      if (!response.success) {
        return Promise.reject(new Error(response?.data?.error?.message || 'Could not fetch orders'));
      }
      // dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersError(storableError(e)));
      throw e;
    });
};
