import React from 'react';
import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import { formatMoney } from '../../util/currency';
import { getMomentFromUTCDate } from '../../util/dates';
import { intlShape } from '../../util/reactIntl';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import {
  NamedLink,
  SquareImage,
} from '../../components';
import { propTypes } from '../../util/types';
import { string } from 'prop-types';

import css from './StoreOrderItem.module.css';
// import moment from 'moment';

const StoreOrderItem = props => {
  const {
    tx,
    intl,
    linkPageName,
  } = props || {};
  const {
    id = '',
    total_price,
    author_total_price,
    order_created_at,
    item_summary,
    stripe_status,
    status: db_status,
    code,
    recipient_first_name,
    recipient_last_name,
    invoice_no,
    img_urls: img_urls_raw,
    item_statuses: item_statuses_raw,
  } = tx || {};
  // console.log('tx', tx);
  // const order_created_moment = moment.tz(order_created_at, 'America/Los_Angeles');
  const order_created_moment = getMomentFromUTCDate(order_created_at, 'America/Los_Angeles');
  const total_price_formatted = formatMoney(intl, new Money(parseFloat(author_total_price || total_price || 0), 'USD'));
  const img_urls = img_urls_raw ? img_urls_raw.split(',') : [];
  const item_statuses = item_statuses_raw ? item_statuses_raw.split(',') : [];

  const isOtherUserBanned = false;
  const isFinal = false;
  const actionNeeded = false;

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });
  const stateClasses = classNames(css.stateName, {
    [css.stateConcluded]: isFinal,
    [css.stateActionNeeded]: actionNeeded,
    [css.stateNoActionNeeded]: !actionNeeded,
  });

  let status = db_status;
  if (Array.isArray(item_statuses) && item_statuses.length > 0) {
    if (item_statuses.every((item) => `${item}`.trim() === 'Shipped')) {
      status = 'Shipped';
    } else if (item_statuses.every((item) => ['Shipped', 'In Delivery'].includes(`${item}`.trim()))) {
      status = 'In Delivery';
    }
  }

  return (
    <div className={css.item}>
      <NamedLink
        className={linkClasses}
        name={linkPageName}
        params={{ code: `${code || id}` }}
      >
        <div className={css.itemDetails}>
          <div className={css.detailRow}>
            <label>Order Date</label>
            <div className={css.itemDate}>{order_created_moment.format('MMMM Do YYYY h:mm a')}</div>
          </div>
          <div className={css.detailRow}>
            <label>Order #</label>
            <div>
              {invoice_no ? `${invoice_no}` : 'Missing Invoice Number'}
            </div>
          </div>
          <div className={css.detailRow}>
            <label>Total</label>
            <div>
              {total_price_formatted}
            </div>
          </div>
          {
            (recipient_first_name || recipient_last_name) && (
              <div className={css.detailRow}>
                <label>Ship To</label>
                <div className={css.itemUsername}>{recipient_first_name} {recipient_last_name}</div>
              </div>
            )
          }
          <div className={css.detailRow}>
            <label>Status</label>
            <div>
              {
                stripe_status
                && status
                && (
                  <Chip label={status} color="success" />
                )
              }
              {
                !stripe_status
                && (
                  <Chip label="not processed" color="warning" />
                )
              }
            </div>
          </div>
        </div>
        <div className={css.itemState}>
          <div>
            {img_urls.map((mi) => (
              <SquareImage src={mi} title={invoice_no} />
            ))}
          </div>
        </div>
      </NamedLink>
    </div>
  )
};

StoreOrderItem.propTypes = {
  // transactionRole: oneOf([TX_TRANSITION_ACTOR_CUSTOMER, TX_TRANSITION_ACTOR_PROVIDER]).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  linkPageName: string.isRequired,
  // stateData: stateDataShape.isRequired,
};

export default StoreOrderItem;