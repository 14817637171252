import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

import { fetchCart } from '../../containers/CartListingPage/CartListingPage.duck';

import {
  queryMerchantReviews,
} from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';
export const QUERY_FEATURED_LISTING_SUCCESS = 'app/ProfilePage/QUERY_FEATURED_LISTING_SUCCESS';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userFeaturedListing: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        // userFeaturedListing: payload.userId === state.userId ? state.userFeaturedListing : null,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], userFeaturedListing: null, queryListingsError: payload };
    case QUERY_FEATURED_LISTING_SUCCESS:
      return { ...state, userFeaturedListing: payload.listing };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const queryFeaturedListingSuccess = listing => ({
  type: QUERY_FEATURED_LISTING_SUCCESS,
  payload: { listing },
});

// ================ Thunks ================ //

export const queryUserListings = (userId, search, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { sort } = queryParams;
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      minStock: 1,
      include: ['author', 'images', 'currentStock'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData.pickupEnabled',
        'publicData.shippingEnabled',
        'publicData.category',
        'publicData.primary_category',
        'publicData.price_original',
        'publicData.variants',
        'publicData.variant_stocks',
        'publicData.shippingPriceInSubunitsOneItem',
      ],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...sortMaybe,
    })
    .then(response => {
      const filtered_response = { ...response };
      if (Array.isArray(filtered_response?.data?.data)) {
        filtered_response.data.data = filtered_response.data.data.filter((listing) => {
          const listing_variant_stock = listing?.attributes?.publicData?.variant_stocks ? JSON.parse(listing?.attributes?.publicData?.variant_stocks) : null;
          if (!listing_variant_stock) {
            return true;
          }
          let has_stock = false;
          if (Array.isArray(listing_variant_stock?.variants) && listing_variant_stock.variants.length > 0) {
            listing_variant_stock.variants.forEach((variant) => {
              if (Array.isArray(variant?.subvariants) && variant.subvariants.length > 0) {
                variant.subvariants.forEach((subvariant) => {
                  if (parseInt(subvariant?.stock || 0, 10) > 0) {
                    has_stock = true;
                  }
                });
              } else if (parseInt(variant?.stock || 0, 10) > 0) {
                has_stock = true;
              }
            });
            return has_stock;
          }
          return true;
        });
      }
      // Pick only the id and type properties from the response listings
      const listingRefs = filtered_response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(filtered_response));
      dispatch(queryListingsSuccess(listingRefs));
      return filtered_response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  queryMerchantReviews(userId?.uuid)
    .then((response) => {
      dispatch(queryReviewsSuccess(response?.data?.data));
    })
    .catch(e => dispatch(queryReviewsError(e)));
  /*
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
  */
};

export const showUser = (userId, config) => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then((response) => {
      const user_public_data = response?.data?.data?.attributes?.profile?.publicData;
      const {
        featuredListingID,
      } = user_public_data || {};
      if (featuredListingID) {
        // console.log('featuredListingID', featuredListingID);
        const {
          aspectWidth = 1,
          aspectHeight = 1,
          variantPrefix = 'listing-card',
        } = config.layout.listingImage;
        const aspectRatio = aspectHeight / aspectWidth;
        const listingId = new UUID(featuredListingID);
        const params = {
          id: listingId,
          include: [
            'author',
            'author.profileImage',
            'images',
            'currentStock'
          ],
          'fields.listing': [
            'title',
            'geolocation',
            'price',
            'publicData.listingType',
            'publicData.transactionProcessAlias',
            'publicData.unitType',
            // These help rendering of 'purchase' listings,
            // when transitioning from search page to listing page
            'publicData.pickupEnabled',
            'publicData.shippingEnabled',
            'publicData.category',
            'publicData.primary_category',
          ],
          'fields.image': [
            // Scaled variants for large images
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',
      
            // Cropped variants for listing thumbnail images
            `variants.${variantPrefix}`,
            `variants.${variantPrefix}-2x`,
            `variants.${variantPrefix}-4x`,
            `variants.${variantPrefix}-6x`,
      
            // Social media
            'variants.facebook',
            'variants.twitter',
      
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
          ],
          ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
        };
        return sdk.listings.show(params)
          .then((data) => {
            const featured_listing_data = denormalisedResponseEntities(data);
            if (Array.isArray(featured_listing_data) && featured_listing_data.length > 0) {
              // console.log('featured_listing_data', featured_listing_data[0]);
              dispatch(queryFeaturedListingSuccess(featured_listing_data[0]));
            }
            return response;
          });
      }
      return response;
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId, config)),
    dispatch(queryUserListings(userId, search, config)),
    dispatch(queryUserReviews(userId)),
    dispatch(fetchCart(config)),
  ]);
};
