/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, Button, NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const categoryContent = (
    <>
      <div className={css.linkRow}>
        <NamedLink name="CategoryPage" params={{ pub_category: 'houseplants' }}>
          Houseplants
        </NamedLink>
      </div>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
            expanded: css.accordionSummaryContentExpanded,
          }}
        >
          <NamedLink name="CategoryPage" params={{ pub_category: 'plants' }}>
            Plants
          </NamedLink>
        </AccordionSummary>
        <AccordionDetails
          className={css.accordionDetails}
        >
          <ul className={css.subnav}>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'perennial-plants' }}>
                Perennials
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'edibles' }}>
                Edibles
              </NamedLink>
            </li>
            {/*
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'roses' }}>
                Roses
              </NamedLink>
            </li>
            */}
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'trees-shrubs' }}>
                Trees & Shrubs
              </NamedLink>
            </li>
            {/*
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'bulbs' }}>
                Bulbs
              </NamedLink>
            </li>
            */}
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'natives' }}>
                Natives &amp; Pollinator Plants
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'ferns' }}>
                Ferns
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'grass' }}>
                Grass
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'plants', pub_subcategory: 'vines' }}>
                Vines
              </NamedLink>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      {/*
      <div className={css.linkRow}>
        <NamedLink name="CategoryPage" params={{ pub_category: 'fertilizer-soil' }}>
          Fertilizer &amp; Soil
        </NamedLink>
      </div>
      */}
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
            expanded: css.accordionSummaryContentExpanded,
          }}
        >
          <NamedLink name="CategoryPage" params={{ pub_category: 'pots-planters' }}>
            Pots &amp; Planters
          </NamedLink>
        </AccordionSummary>
        <AccordionDetails
          className={css.accordionDetails}
        >
          <ul className={css.subnav}>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'pots-planters', pub_subcategory: 'raised-beds' }}>
                Raised Beds
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'pots-planters', pub_subcategory: 'rolling-planters' }}>
                Rolling Planters
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'pots-planters', pub_subcategory: 'planters' }}>
                Planters
              </NamedLink>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
            expanded: css.accordionSummaryContentExpanded,
          }}
        >
          <NamedLink name="CategoryPage" params={{ pub_category: 'tools-accessories' }}>
            Tools &amp; Accessories
          </NamedLink>
        </AccordionSummary>
        <AccordionDetails
          className={css.accordionDetails}
        >
          <ul className={css.subnav}>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'tools-accessories', pub_subcategory: 'seed-starting' }}>
                Seeds Starting
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'tools-accessories', pub_subcategory: 'garden-tools' }}>
                Garden Tools
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'tools-accessories', pub_subcategory: 'composters' }}>
                Composters
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'tools-accessories', pub_subcategory: 'planning-design' }}>
                Planning &amp; Design
              </NamedLink>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
            expanded: css.accordionSummaryContentExpanded,
          }}
        >
          <NamedLink name="CategoryPage" params={{ pub_category: 'seeds' }}>
            Seeds
          </NamedLink>
        </AccordionSummary>
        <AccordionDetails
          className={css.accordionDetails}
        >
          <ul className={css.subnav}>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'seeds', pub_subcategory: 'vegetables' }}>
                Vegetables
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'seeds', pub_subcategory: 'herbs' }}>
                Herbs
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="SubcategoryPage" params={{ pub_category: 'seeds', pub_subcategory: 'flowers' }}>
                Flowers
              </NamedLink>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
            expanded: css.accordionSummaryContentExpanded,
          }}
        >
          <span className={css.summaryText}>Browse By Experience</span>
        </AccordionSummary>
        <AccordionDetails
          className={css.accordionDetails}
        >
          <ul className={css.subnav}>
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'sustainability-shop' }}>
                Sustainability Shop
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'small-space-gardening' }}>
                Small Space Gardening
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'garden-to-table' }}>
                Garden To Table
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'gardening-essentials' }}>
                Gardening Essentials
              </NamedLink>
            </li>
            {/*
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'shop-small-local' }}>
                Shop Small &amp; Local
              </NamedLink>
            </li>
            */}
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'rare-unique-varieties' }}>
                Rare &amp; Unique Varieties
              </NamedLink>
            </li>
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'indoor-gardening' }}>
                Indoor Gardening
              </NamedLink>
            </li>
            {/*
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'dify' }}>
                DIFY
              </NamedLink>
            </li>
            */}
            <li className={css.subnav_item}>
              <NamedLink name="ExperiencePage" params={{ pub_experience: 'pollinator-garden' }}>
                Pollinator Garden
              </NamedLink>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  )

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          {categoryContent}
        </div>
        {/*
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
        */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const isStore = currentUser?.attributes?.profile?.publicData?.isStore || false;

  return (
    <div className={css.root}>
      {/*
      <AvatarLarge className={css.avatar} user={currentUser} />
      */}
      <div className={css.content}>
        <div className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </div>
        {categoryContent}
        <div className={css.accountSection}>
          <div className={css.headerRow}>
            ACCOUNT
          </div>
          {
            !isStore && (
              <div className={css.linkRow}>
                <NamedLink
                  className={classNames(css.inbox, currentPageClass('OrdersPage'))}
                  name="OrdersPage"
                  params={{ tab: 'orders' }}
                >
                  <FormattedMessage id="TopbarMobileMenu.ordersLink" />
                  {notificationCountBadge}
                </NamedLink>
              </div>
            )
          }
          {
            isStore && (
              <div className={css.linkRow}>
                <NamedLink
                  className={classNames(css.inbox, currentPageClass('StoreOrdersPage'))}
                  name="StoreOrdersPage"
                  params={{ tab: 'store_orders' }}
                >
                  <FormattedMessage id="TopbarMobileMenu.storeOrdersLink" />
                  {notificationCountBadge}
                </NamedLink>
              </div>
            )
          }
          {
            isStore && (
              <div className={css.linkRow}>
                <NamedLink
                  className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
                  name="ManageListingsPage"
                >
                  <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
                </NamedLink>
              </div>
            )
          }
          {
            isStore && (
              <div className={css.linkRow}>
                <NamedLink
                  className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
                  name="ProfileSettingsPage"
                >
                  <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
                </NamedLink>
              </div>
            )
          }
          <div className={css.linkRow}>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
            </NamedLink>
          </div>
          <div className={css.linkRow}>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('CartListingPage'))}
              name="CartListingPage"
            >
              <FormattedMessage id="TopbarMobileMenu.cartLink" />
            </NamedLink>
          </div>

          <div className={css.linkRow}>
            <Button rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </Button>
          </div>
        </div>

        <div className={css.spacer} />

      </div>
      {/*
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
      */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
