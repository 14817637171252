import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import {
  formatMoney,
  getMaxMinFromVariants,
} from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

const { Money } = sdkTypes;

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const {
    price: base_price,
    publicData,
    config,
    intl
  } = props;
  const {
    listingType,
    price_original: price_original_raw,
    variants: variants_json,
    shippingPriceInSubunitsOneItem,
  } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && base_price) {
    return null;
  }
  const price = new Money(parseInt(base_price?.amount || 0, 10) + parseInt(shippingPriceInSubunitsOneItem || 0, 10), base_price?.currency || config.currency);

  const product_variants = variants_json ? JSON.parse(variants_json) : null;
  const {
    formattedMaxPrice,
    maxPriceTitle,
    formattedMinPrice,
    minPriceTitle,
    max_price,
    min_price,
  } = getMaxMinFromVariants(product_variants, config.currency, intl);

  const price_original = new Money(parseInt(price_original_raw || 0, 10) + parseInt(shippingPriceInSubunitsOneItem || 0, 10), config.currency);

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  let {
    formattedPrice,
    priceTitle,
  } = priceData(price, config.currency, intl);
  if (max_price) {
    formattedPrice = formattedMaxPrice;
    priceTitle = maxPriceTitle;
  }
  if (min_price && min_price !== max_price) {
    formattedPrice = `${formattedMinPrice} - ${formattedMaxPrice}`;
    priceTitle = `${minPriceTitle} - ${maxPriceTitle}`;
  }
  const {
    formattedPrice: formattedOriginalPrice,
    priceTitle: originalPriceTitle,
  } = priceData(price_original, config.currency, intl);
  const showOriginalPrice = (
    !!price_original_raw
    && parseInt(price_original_raw || 0, 10) !== parseInt(base_price?.amount || 0, 10)
  );
  return (
    <div className={css.price}>
      {
        showOriginalPrice
        && (
          <div className={css.originalPriceValue} title={originalPriceTitle}>
            {formattedOriginalPrice}
          </div>
        )
      }
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isSquare,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  let category = publicData?.primary_category;
  if (!category) {
    category = Array.isArray(publicData?.category) ? publicData.category[0] : publicData?.category || 'na';
  }
  const authorName = author?.attributes?.profile?.publicData?.storeName || author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  let variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];
  // console.log('firstImage?.attributes?.variants', firstImage?.attributes?.variants);
  if (isSquare) {
    variants = ['square-small2x', 'default', 'scaled-medium'];
  }

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <NamedLink className={classes} name="CategoryListingPage" params={{ id, slug, category }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        rootClassName={isSquare && css.aspectRatioWrapperSquare}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          config={config}
          intl={intl}
        />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  isSquare: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  isSquare: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
